<template>
  <ModernModalLayout
    :size="size"
    :title="message('title')"
    :persistent="loading"
    :close-disabled="loading"
    :watch-for="data"
  >
    <v-alert
      v-if="!$wait('fetchingItems') && !innerItems.length"
      type="info"
      text
      border="left"
    >
      {{ message('warning') }}
    </v-alert>

    <div v-else>
      <div v-if="description" class="mb-2">{{ description }}</div>
      <Select
        v-model="data.innerValue"
        hide-details="auto"
        :items="innerItems"
        :item-value="itemValue"
        :item-disabled="itemDisabled"
        :multiple="multiple"
        :loading="$wait('fetchingItems')"
        :disabled="$wait('fetchingItems') || loading"
        label=""
      />
      <div v-if="message('hint')" class="text-sm-body-2 pt-2">
        {{ message('hint') }}
      </div>
    </div>

    <v-alert v-if="error" type="error" text border="left">
      {{ error }}
    </v-alert>

    <template #actions:append>
      <v-btn
        v-if="innerItems.length"
        class="px-10"
        depressed
        color="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import Select from '@/components/controls/Select';
import messagable from '@/utils/mixins/messagable';
import waitable from '@/utils/mixins/waitable';
import { xhrErrorMessage } from '@/utils/helpers';

const MESSAGES = {
  title: 'Редактирование чего-то',
  warning: 'Чего-то не хватает',
  hint: '',
};

export default {
  components: {
    ModernModalLayout,
    Select,
  },

  mixins: [messagable(MESSAGES), waitable],

  props: {
    value: {
      type: [Array, Number, String],
      required: true,
    },
    items: {
      type: [Array, Promise],
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    itemDisabled: {
      type: [String, Function],
      default: 'disabled',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
  },

  data() {
    return {
      data: {
        innerValue:
          this.multiple && typeof this.value[0] === 'object'
            ? this.value.map(item => item.id || item.key)
            : this.value,
      },
      innerItems: [],

      error: null,
      loading: false,
    };
  },

  created() {
    if (Array.isArray(this.items)) this.innerItems = this.items;

    if (this.items instanceof Promise)
      this.$loading(
        this.items.then(items => (this.innerItems = items)),
        'fetchingItems',
      );
  },

  methods: {
    async handleSubmit() {
      this.error = null;
      this.loading = true;

      try {
        await this.onSubmit(this.data.innerValue);
        this.loading = false;
        this.$emit('close');
      } catch (e) {
        console.error(e);
        this.error = xhrErrorMessage(e);
        this.loading = false;
      }
    },
  },
};
</script>
